import Http from "./http";
import api from "./configApi";
import responseHandle from "./responseHandle";

const getCotacoes = async ({ login }) =>{

    return Http.get(`${ api.baseURL }/rest/consultarCotacao?cgcFornecedor=${ login }`,'02,0201')
    .then(responseHandle)
    .then((response) => {
        return response.json();
    });

}

const getPedidos = async ({ login }) =>{

    return Http.get(`${ api.baseURL }/rest/PedidoCompra/Vencedor?cgcfornecedor=${ login }`,'02,0201')
    .then(responseHandle)
    .then((response) => {
        return response.json();
    });

}

const getAcesso = async ({ login, senha  }) =>{
    return Http.post(`${ api.baseURL }/rest/logar`,
    {
        login,
        senha
    },'02,0201')
    .then(responseHandle)
    .then((response) => {
        return response.json();
    });

}

const getCondicoes = async () =>{
    
    return Http.get(`${ api.baseURL }/rest/consultarCondicaoPagamento`,'02,0201')
    .then(responseHandle)
    .then((response) => {
        return response.json();
    });

}

const salvaCotacao = async ( cotacao ) =>{
    
    cotacao = sanitizeObject( Object.assign({}, cotacao) )

    return Http.put(`${ api.baseURL }/rest/atualizarCotacao`, cotacao,`02,${ cotacao.filial }`)
    .then(responseHandle)
    .then((response) => {
        return response.json();
    });

}

const getFormasPagamentos = async () =>{
    
    return Http.get(`${ api.baseURL }/rest/formaPagamento`,'02,0201')
    .then(responseHandle)
    .then((response) => {
        return response.json();
    });

}

function sanitizeObject( cotacao ){

    cotacao.itens.forEach( item => {
        if(item.dataEntrega.indexOf("-") >= 0){
            item.dataEntrega = item.dataEntrega.split('-').reverse().join('/');
        }

        if(item.necessidade) delete item.necessidade
        if(item.percIcms) delete item.percIcms

        item.observacao = item.observacao || ""
        item.obsItem = item.observacao
        item.precoUnitario = item.precoUnitario*1
        item.total = item.precoUnitario*item.qtde
        item.percDesconto = item.percDesconto*1 || 0
        item.valorIcms = item.valorIcms*1 || 0
        item.percIpi = item.percIpi*1 || 0
        //item.qtdeAtendida = item.qtdeAtendida*1 || 0
    })

    return cotacao;

}

export {
    getCotacoes,
    getPedidos,
    getCondicoes,
    getAcesso,
    salvaCotacao,
    getFormasPagamentos
}