<template>
  <nav class="navbar navbar-expand navbar-light bg-light topbar shadow bg-img-cabecalho">
    <div class="container-fluid">
      <!-- Sidebar Toggle (Topbar) -->
      <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
        <i class="fa fa-bars"></i>
      </button>

      <!-- Topbar Navbar -->
      <ul class="navbar-nav ml-auto">
        <!-- Nav Item - User Information -->
        <li class="nav-item dropdown no-arrow">
          <a
            class="nav-link dropdown-toggle"
            @click="signOut()"
          >
            <i class="fas fa-2x fa-sign-out-alt"></i>
          </a>
        </li>
      </ul>
    </div>
  </nav>
  <!-- End of Topbar -->
</template>


<script>

export default {
  data(){
    return{
      usuario:{
        email:""
      }
    }    
  },
  mounted(){
     const { email } = JSON.parse( localStorage.getItem('dadosUsuario') )
     this.usuario.email = email
  },  
  methods: {
    signOut() {
        localStorage.setItem('dadosUsuario', null);
        this.$router.replace({ name:"login" });
    }
  }
};
</script>

<style>
.bg-img-cabecalho{
  background-image: url("../assets/bg-cabecalho.jpg");
  background-size: 100% 100%;
  background-position:  center;
}
</style>
