export const responseHandle = (response) => {
    if (!response.ok) {
      if (response.status == 401) {
        return response
      } else {
        const error = new Error(`Error ${response.status}: ${response.statusText}`)
        error.status = response.status
        error.statusText = response.statusText
  
        throw error
      }
    };
  
    return response;
  }


  export default { responseHandle };