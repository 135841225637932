<template lang="html">
    <div class="integration-list">
    <div class="row my-5">
      <div class="col-lg-12">
        <h1 class="text-primary font-weight-bold">
          Lista |
            <span class="text-acp font-weight-light">
              Cotações
            </span>
        </h1>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-lg-12">
        <div class="input-group mr-4">
          <input
            type="text"
            v-model="filter"
            placeholder="Digite para Procurar"
            class="input form-control search"
          />
          <div class="input-group-append">
            <button
              type="button"
              class="btn btn btn-light"
            >
              <i class="fa fa-eraser"></i>
            </button>
            <button type="button" class="btn btn btn-acp">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card shadow mb-4">
          <div class="card-body">
            <div class="table-responsive">
              <table id="tblContratos" class="table table-striped table-hover" >
                <thead>
                  <tr>
                    <th>Número da Cotação</th>
                    <th>Cnpj</th>
                    <th>UF Filial</th>
                    <th>Filial</th>
                    <th>Nome do Fornecedor</th>
                    <th>Data de Emissão</th>
                    <th>Solicitante</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="cotacao in cotacoes"
                    :key="cotacao.numeroCotacao"
                  >
                    <td>{{ cotacao.numeroCotacao }}</td>
                    <td>{{ cotacao.cgcFilial }}</td>
                    <td>{{ cotacao.estadoFilial }}</td>
                    <td>{{ cotacao.nomeFilial }}</td>
                    <td>{{ cotacao.nomeFornecedor }}</td>
                    <td>{{ cotacao.emissao }}</td>
                    <td>{{ cotacao.solicitante }}</td>
                    <td class="text-left">
                      <button
                        class="btn btn-acp m-0"
                        @click="openModalItens( cotacao )"
                      >
                        <i class="fas fa-list" title="Itens do contrato"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>



    <sweet-modal :width="'100%'" :blocking="true" ref="itensModal"
    title="" overlay-theme="dark">

      <h1 class="text-acp">Itens da Cotação</h1>

      <div class="row">
        <div class="col-lg-12">
          <div class="card border shadow mb-4">
            <div class="card-body">

              <b-row>
                <b-col lg="3">
                  <label for class="text-acp"><strong>Condição de Pagamento</strong></label>
                  <select class="form-control" v-model="cotacaoSelecionada.condicaoPgto">
                    <option value="">Selecione...</option>
                    <option 
                      v-for="condicao in condicoesPagamento"
                      :key="condicao.codigo"
                      :value="condicao.codigo"
                    >
                      {{condicao.descricao}}
                    </option>
                  </select>
                </b-col>

                <b-col lg="3">
                  <label for class="text-acp"><strong>Forma de Pagamento</strong></label>
                  <select class="form-control" v-model="cotacaoSelecionada.formaPgto">
                    <option value="">Selecione...</option>
                    <option 
                      v-for="forma in formasPagamento"
                      :key="forma.codigo"
                      :value="forma.codigo"
                    >
                      {{forma.descricao}}
                    </option>
                  </select>
                </b-col>

                <b-col lg="3">
                  <label for class="text-acp"><strong>Tipo do Frete</strong></label><br/>
                  <select class="form-control" v-model="cotacaoSelecionada.tipoFrete">
                    <option value="">Selecione...</option>
                    <option value="cif">CIF</option>
                    <option value="fob">FOB</option>
                  </select>
                </b-col>

                <b-col lg="3">
                  <label for class="text-acp"><strong>Data de Entrega</strong></label><br/>
                  <b-form-input v-model="dataEntrega" type="date" :min="today" @change.native="carregaDataEntrega()"/>
                </b-col>

              </b-row>

              <hr/>

              <b-row>
                <div class="col-lg-12 table-responsive">
                  <table id="tblKPI" class="table table-striped table-hover table-bordered" >
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Cód. Produto</th>
                        <th>Descrição</th>
                        <th>UM</th>
                        <th>Qtd.</th>
                        <th style="text-align: center;">
                          Possui o Item?<br/>
                          <input type="checkbox" v-model="selectAllItems" @change="onChangeCheck()"/>
                        </th>
                        <th>Valor Unitário (Com Impostos)</th>
                        <!-- <th>Vlr Unitário</th> -->
                        <!-- <th>IPI (%)</th> -->
                        <!-- <th>ICMS ST (%)</th> -->
                        <th>Total</th>
                        <th>Data de entrega</th>
                        <th>Marca</th>
                        <th>Observação</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in cotacaoSelecionada.itens"
                        :key="item.item"
                      >
                        <td>{{ item.item }}</td>
                        <td>{{ item.produto }}</td>
                        <td :title="item.descProduto">{{ item.descProduto }}</td>
                        <td>{{ item.UM }}</td>
                        <td>{{ item.qtde | formatNumber}}</td>
                        <td align="center">
                          <input type="checkbox" v-model="item.selectItem" @change="onChangeCheckItem(item)"/>
                        </td>
                        <td>
                          <money v-model="item.precoUnitario" v-bind="money" :readonly="!item.selectItem" class="form-control" @change.native="calculaTotalItem(item)"></money>
                        </td>
                        <!-- <td>
                          <money v-model="item.percIpi" v-bind="money" maxlength="5" :readonly="!item.selectItem" class="form-control" @change.native="calculaTotalItem(item)"></money>
                        </td>
                        <td>
                          <money v-model="item.percIcms" v-bind="money" maxlength="5" :readonly="!item.selectItem" class="form-control" @change.native="calculaTotalItem(item)"></money>
                        </td> -->
                        <td>
                          <money v-model="item.total" class="form-control" readonly></money>
                        </td>
                        <td>
                          <b-form-input
                            v-model="item.dataEntrega"
                            type="date"
                            :min="today"
                          />
                        </td> 
                        <td>
                          <b-form-input type="text" v-model="item.marca"/>
                        </td>                         
                        <td>
                          <b-form-input type="text" v-model="item.observacao"/>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-row>
            </div>
          </div>
        </div>
      </div>

      <b-row v-if="cotacaoSelecionada.itens.length > 1">
        <b-col lg="3">
          <label for>Total do orçamento</label>
          <money v-model="cotacaoSelecionada.totalOrcamento" class="form-control" readonly ></money>
        </b-col>
      </b-row>

      <button
        class="btn btn-acp m-0"
        @click="enviaCotacao"
      >
        Responder cotação
      </button>
    
    </sweet-modal>

  </div>
</template>

<script>

import numeral from "numeral"
import $ from "jquery";
import 'datatables.net-bs4'
import { SweetModal } from 'sweet-modal-vue'
import {Money} from 'v-money'
import { getCotacoes, getCondicoes, salvaCotacao, getFormasPagamentos } from "../../backend"
import moment from "moment";

export default {
    data(){
        return {
            cotacoes: [],
            filter: "",
            oTable: null,
            itens: [],
            cotacaoSelecionada: {
              tipoFrete:"",
              condicaoPgto:"",
              formaPgto:"",
              itens:[]
            },
            condicoesPagamento:[],
            money: {
              decimal: '.',
              thousands: '',
              prefix: '',
              suffix: '',
              precision: 2,
              masked: true
            },
            dataEntrega: null,
            selectAllItems: false
        }
    },
    mounted() {
        this.fetchCotacoes()
        this.fetchCondicoes()
        this.fetchFormaPagamento()
    },
    components:{
        SweetModal,
        Money
    },
    methods:{
        createTable(){
            let vm = this;
            this.$nextTick(() =>{
                this.oTable = $("#tblContratos").DataTable({
                "paging": false,
                "searching": false,
                "lengthMenu": [[25, 50, 100], [25, 50, 100]],
                "language":{
                    "url": "https://cdn.datatables.net/plug-ins/1.10.24/i18n/Portuguese-Brasil.json"
                },
                "initComplete": function(settings) {
                    vm.filter = settings.oLoadedState === null ? "" : settings.oLoadedState.search.search;
                    $("#tblContratos_filter").hide()
                },
                "stateSave": true
                });
            })
        },
        destroyTable(){
          if(this.oTable){
            this.oTable.destroy();
          } 
        },
        async fetchCotacoes(){

            const { login } = JSON.parse( localStorage.getItem('dadosUsuario') )

            const result =  await getCotacoes({ login });

            if(result.errorCode) return;

            this.cotacoes = result.listaCotacao; 
        },
        async fetchFormaPagamento(){

            const { login } = JSON.parse( localStorage.getItem('dadosUsuario') )

            const result =  await getFormasPagamentos({ login });

            if(result.errorCode) return;

            this.formasPagamento = result.listaFormasPgto; 
        },
        async fetchCondicoes(){
            const result =  await getCondicoes();
            if(result.errorCode) return;
            this.condicoesPagamento = result.Retorno_consultarCondicaoPagamento; 
        },
        openModalItens(cotacao){
          this.cotacaoSelecionada = Object.assign({}, cotacao);
          this.cotacaoSelecionada.tipoFrete = "";
          this.cotacaoSelecionada.condicaoPgto = "";
          this.$refs.itensModal.open();
        },
        formValido(){
          if(this.cotacaoSelecionada.tipoFrete === ""){
            this.$notify({
              group: 'app',
              type: "warn",
              text:"Necessário informar o tipo do frete!"
            });
            return false;
          }

          if(this.cotacaoSelecionada.condicaoPgto === ""){
            this.$notify({
              group: 'app',
              type: "warn",
              text: "Necessário informar a condição de pagamento!"
            });
            return false;
          }

          if(this.cotacaoSelecionada.formaPgto === ""){
            this.$notify({
              group: 'app',
              type: "warn",
              text: "Necessário informar a forma de pagamento!"
            });
            return false;
          }

          for(const item of this.cotacaoSelecionada.itens){
            if(item.selectItem === true && item.precoUnitario == '0.00'){
              this.$notify({
                group: 'app',
                type: "warn",
                text: `Necessário informar o valor unitário para o item ${ item.descProduto }`
              });
              return false;
            }

            if(item.selectItem === true && !item.marca){
              this.$notify({
                group: 'app',
                type: "warn",
                text: `Necessário informar a marca para o item ${ item.descProduto }`
              });
              return false;
            }

            if(item.selectItem === true && !item.observacao){
              this.$notify({
                group: 'app',
                type: "warn",
                text: `Necessário informar a observação para o item ${ item.descProduto }`
              });
              return false;
            }

            // if(item.precoUnitario === '0.00' && !item.observacao){
            //   this.$notify({
            //     group: 'app',
            //     type: "warn",
            //     text: `Necessário informar a observação para o item ${ item.descProduto }`
            //   });
            //   return false;
            // }

          }
          return true;
        },
        async enviaCotacao(){     
          if(!this.formValido()) return;

          const result = await salvaCotacao( this.cotacaoSelecionada );

          if(result.statusCode === 200){
            this.destroyTable();
            await this.fetchCotacoes();          
            this.$refs.itensModal.close();
            this.$notify({
              group: 'app',
              type: "success",
              title: "Cotação enviada com sucesso!"
            });           
          }else{

            this.$notify({
              group: 'app',
              type: "warn",
              title: "Ops! Erro inesperado",
              text: result.errorMessage
            });
          }
        },
        calculaTotalItem(item){
          item.total = parseFloat( item.qtde ) * parseFloat( item.precoUnitario )

          this.cotacaoSelecionada.totalOrcamento = this.cotacaoSelecionada.itens.reduce(( total, item )=>{
            return total + (item.total * 1) 
          }, 0)
        },
        carregaDataEntrega(){
          this.cotacaoSelecionada.itens.forEach( item => item.dataEntrega = this.dataEntrega )
        },
        validaQuantidadeAtendida(item){
          const qtdeAtendida = parseFloat( item.qtdeAtendida )
          const qtde = parseFloat( item.qtde )

          if(qtdeAtendida < 0 || qtdeAtendida > qtde){
            this.$notify({
              group: 'app',
              type: "warn",
              title: "Quantidade atendida não pode ser, valor negativo ou maior que a quantidade solicitada.",
              text: result.errorMessage
            });
          }
        },
        onChangeCheck(){
          for(const item of this.cotacaoSelecionada.itens){
            this.onChangeCheckItem(item);
            item.selectItem = this.selectAllItems
          }
        },
        onChangeCheckItem(item){
          item.precoUnitario = 0;
        }
    },
    watch:{
        filter:{
            handler(val){
                this.oTable.search( val ).draw();
            }
        },
        cotacoes:{
            handler(val){
                this.createTable();
            }
        }
    },
    filters:{
      formatNumber(value){
        return numeral( parseFloat(value) ).format("0.0000")
      }
    },
    computed:{
      today() {
        return moment().format("YYYY-MM-DD");
      }
    }
}
</script>
