import Vue from 'vue'
import VueRouter from 'vue-router'
import Cotacao from '../views/Cotacao/Cotacao.vue'
import PurchaseOrder from '../views/PurchaseOrder/PurchaseOrder.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: `/`,
    name: 'login',
    component: Login,
    meta: {
      layout: 'login'
    }
  },
  {
    path: `/cotacao`,
    name: 'cotacao',
    component: Cotacao,
    meta: {
      requiresAuth: true
    }
  },
  { 
    path: `/pedidos`,
    name: 'pedido-compra',
    component: PurchaseOrder,
    meta: {
      requiresAuth: true
    }
  }


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('dadosUsuario') === 'null') {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
