<template lang="html">
    <div class="integration-list">
    <div class="row my-5">
      <div class="col-lg-12">
        <h1 class="text-primary font-weight-bold">
          Lista |
            <span class="text-acp font-weight-light">
              Pedidos de Compras
            </span>
        </h1>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-lg-12">
        <div class="input-group mr-4">
          <input
            type="text"
            v-model="filter"
            placeholder="Digite para Procurar"
            class="input form-control search"
          />
          <div class="input-group-append">
            <button
              type="button"
              class="btn btn btn-light"
            >
              <i class="fa fa-eraser"></i>
            </button>
            <button type="button" class="btn btn btn-acp">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card shadow mb-4">
          <div class="card-body">
            <div class="table-responsive">
              <table id="tblPedidos" class="table table-striped table-hover" >
                <thead>
                  <tr>
                    <th>Nr. Pedido</th>
                    <th>Filial</th>
                    <th>Emissão</th>
                    <th>Código/Loja Fornecedor</th>
                    <th>Fornecedor</th>
                    <th>Condição de Pagamento</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(pedido, index) in pedidos"
                    :key="index"
                  >
                    <td>{{ pedido.numeroPedido }}</td>
                    <td>{{ pedido.nomeFilial }}</td>
                    <td>{{ pedido.dataEmissao }}</td>
                    <td>{{ pedido.codigoFornecedor }} / {{ pedido.lojaFornecedor }}</td>
                    <td>{{ pedido.nomeFornecedor }}</td>
                    <td>{{ pedido.descricaoPgto }}</td>
                    <td class="text-left">
                      <button
                        class="btn btn-acp m-0"
                        @click="openModalItens( pedido )"
                      >
                        <i class="fas fa-list" title="Itens do contrato"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


    <sweet-modal :width="'65%'" :blocking="true" ref="itensModal"
    title="" overlay-theme="dark">

      <h4>Itens do Pedido</h4>

      <div class="row">
        <div class="col-lg-12">
          <div class="card shadow mb-4">
            <div class="card-body">
              <div class="table-responsive">
                <table id="tblKPI" class="table table-striped table-hover" >
                  <thead>
                    <tr>
                      <th>Código Produto</th>
                      <th>Descrição</th>
                      <th>Unidade de Medida</th>
                      <th>Quantidade</th>
                      <th>Valor Unitário</th>
                      <th>Valor Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in itens"
                      :key="index"
                    >
                      <td>{{ item.codigoProduto }}</td>
                      <td>{{ item.descProduto }}</td>
                      <td>{{ item.unidadeMedida }}</td>
                      <td>{{ item.quantidade | formatNumber}}</td>
                      <td>{{ item.valorUnitario | formatNumberMoney}}</td>
                      <td>{{ item.valorTotal | formatNumberMoney}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </sweet-modal>

  </div>
</template>

<script>

import numeral from "numeral"
import $ from "jquery";
import 'datatables.net-bs4'
import { SweetModal } from 'sweet-modal-vue'
import { getPedidos } from '../../backend';

export default {
    data(){
        return {
            pedidos: [],
            itens:[],
            filter: "",
            oTable: null
        }
    },
    components:{
        SweetModal
    },
    mounted() {
        this.fetchPedidos()
    },
    methods:{
        createTable(){
            let vm = this;
            this.$nextTick(() =>{
                this.oTable = $("#tblPedidos").DataTable({
                "lengthMenu": [[25, 50, 100], [25, 50, 100]],
                "language":{
                    "url": "https://cdn.datatables.net/plug-ins/1.10.24/i18n/Portuguese-Brasil.json"
                },
                "initComplete": function(settings) {
                    vm.filter = settings.oLoadedState.search.search;
                    $("#tblPedidos_filter").hide()
                },
                "stateSave": true
                });
            })
        },
        async fetchPedidos(){
          const { login } = JSON.parse( localStorage.getItem('dadosUsuario') )

          const result =  await getPedidos({ login });

          if(result.errorCode) return;

          this.pedidos = result.listaPedidoVencedor; 

          this.createTable();
        },
        openModalItens(pedido){

            this.itens = [];

            this.itens = pedido.itens;

            this.$refs.itensModal.open();

        }
    },
    watch:{
        filter:{
            handler(val){
                this.oTable.search( val ).draw();
            }
        }
    },
    filters:{
      formatNumber(value){
        return numeral( parseFloat(value) ).format("0.00")
      }
    }
}
</script>
