export const headers = (tenantID) => {
    return {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: 'Basic Zmx1aWc6Zmx1aWc=',
        tenantID
    };
};


export default {

    get: (url, tenantID, options) => {
        const fetchOptions = Object.assign({
            method: 'GET',
            headers: headers( tenantID ),
        }, options);
        return fetch(url, fetchOptions);
    },

    post: (url, body, tenantID, options) => {
      const fetchOptions = Object.assign({
        method: 'POST',
        headers: headers(tenantID),
      }, options);
      if (body !== undefined) {
        fetchOptions.body = JSON.stringify(body);
      }
      return fetch(url, fetchOptions);
    },

    put: (url, body, tenantID, options) => {
      const fetchOptions = Object.assign({
        method: 'PUT',
        headers: headers(tenantID),
      }, options);
      if (body !== undefined) {
        fetchOptions.body = JSON.stringify(body);
      }
      return fetch(url, fetchOptions);
    }


}