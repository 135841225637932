<template>
  <b-row class="vh-100 row-login">
    <b-col lg="12" md="12" sm="12" class="d-flex justify-content-center align-items-center">
      <div class="col-4 left-login">
        <b-form>
          <b-form-group
            label="Login"
            label-for="login"
            >
              <b-form-input
                id="login"
                type="text"
                placeholder="Digite seu login"
                autocomplete="true"
                v-model.trim="$v.form.login.$model"
                :state="getValidation('login')"
              ></b-form-input>
            </b-form-group>
 
            <b-form-group
              label-for="senha"
            >
              <label class="d-flex justify-content-between">
                Senha
              </label>
 
              <b-form-input
                id="senha"
                type="password"
                placeholder="Digite sua senha"
                v-model="$v.form.senha.$model"
                :state="getValidation('senha')"
              ></b-form-input>
            </b-form-group>
            

            <b-button
              type="button"
              variant="acp"
              block
              @click="login">
              <i class="fas fa-sign-in-alt"></i> Entrar
            </b-button>

            <p class="text-danger" v-if="acessoInvalido">{{mensagemErro}}</p>
        </b-form>
      </div>
    </b-col>

  </b-row>
</template>
 
<script>

import { required, minLength, email } from "vuelidate/lib/validators";
import { getAcesso } from "../backend"
import { SweetModal } from 'sweet-modal-vue'
 
export default {
  data() {
    return {
      form: {
        login: "",
        senha: ""
      },
      acessoInvalido: false,
      mensagemErro:""
    }
  },
  validations: {
    form: {
      login: {
        required
      }, 
      senha: {
        required, 
      },
    }
  },
  components:{
    SweetModal
  },
 
  methods: {
    async login() {
      debugger;
        this.$v.$touch();
        if(this.$v.form.$error) {
            return;
        }

        const { login, senha } = this.form;

        const resultAcesso = await getAcesso({ login, senha })

        if(resultAcesso.errorCode){
            this.acessoInvalido = true;
            this.mensagemErro = resultAcesso.errorMessage;
            return;
        }

        localStorage.setItem('dadosUsuario', JSON.stringify( this.form ));

        this.$router.push({ name: 'cotacao' })
    },

    getValidation(field) {
        if(this.$v.form.$dirty === false) {
            return null; //enquanto o form não for submetido não valida os campos
        }

        return !this.$v.form[field].$error;
    },
    getValidationRecuperaSenha(field) {
        if(this.$v.formRecuperaSenha.$dirty === false) {
            return null; //enquanto o form não for submetido não valida os campos
        }
    
        return !this.$v.formRecuperaSenha[field].$error;
    },
    openModalRecuperaSenha(){
      this.$refs.recuperaSenhaModal.open();
    }
 
  }
}
</script>
 
<style>
 
*, /*resetar o estilo da página*/
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
 
.row-login {
  margin-left: 0;
}
 
.left-login {
  background-color: #F2F2F2;
  padding: 30px;
}

.login-form{
  background:#1a11117a;
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 100px;
  border-radius: 50px;
  color: white;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}
.login-heading{
  text-align: center;
  margin-top: 20px;
}
.container{
  background: #09090a4f;
  border-radius: 50px;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.38);
}
.btn-primary{
  width: 100%;
}
 
</style>