<template>
  <ul
    class="navbar-nav sidebar sidebar-dark accordion bg-img-menu"
    id="accordionSidebar"
  >

    <li class="nav-item">
      <router-link class="nav-link" to="/cotacao">
        <i class="fas fa-file-contract"></i>
        <span>Cotações</span>
      </router-link>
    </li>

    <!--
    <li class="nav-item">
      <router-link class="nav-link" to="/pedidos">
        <i class="fas fa-shopping-basket"></i>
        <span>Pedidos</span>
      </router-link>
    </li>
    -->

    <hr class="sidebar-divider d-none d-md-block" />    

    <div class="text-center d-none d-md-inline mt-5">
      <button class="rounded-circle border-0" id="sidebarToggle"></button>
    </div>
  </ul>
</template>

<script>
import $ from "jquery";

export default {
  mounted() {
    // $('body').toggleClass('sidebar-toggled')
    // $('.sidebar').toggleClass('toggled')
    //
    // if ($(".sidebar").hasClass("toggled")) {
    //   $(".sidebar .collapse").collapse("hide");
    // }

    $("#sidebarToggle, #sidebarToggleTop").on("click", () => {
      $("body").toggleClass("sidebar-toggled");
      $(".sidebar").toggleClass("toggled");
      if ($(".sidebar").hasClass("toggled")) {
        //$(".sidebar .collapse").collapse("hide");
        $(".sidebar .collapse").removeClass("show");
      }
    });

    $("body").on("click", () => {
      //if ($(".sidebar").hasClass("toggled")) {
        $(".sidebar .collapse").removeClass("show");
      //}
    });
  }
};
</script>

<style lang="scss">
ul {
  li {
    p {
      margin: 0;
    }
  }
}

.bg-img-menu{
  background-image: url("../assets/bg-menu.jpg");
  background-size: 100% 100%;
  background-position:  center;
}
</style>
